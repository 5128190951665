<header id="inicio">
    <nav>
        <div class="menu-header">
            <img src="assets/image/icono.png" alt="">
        </div>
        <div class="menu-header-basic languageEsp">
            <img src="assets/image/FlagSpain-40.png" id="translateEsI" data-text="es" alt="">
            <a class="centered" href="#" id="translateEs" data-text="es">ESP</a>
        </div>
        <div class="menu-header-basic languageCat" >
            <img src="assets/image/FlagCatalan-40.png" id="translateCaI" data-text="ca" alt="">
            <a href="#" class="centered" id="translateCa" data-text="ca">CAT</a>
        </div>
        <div class="menu-header-basic shopCartIcon" >
            <a href="https://bit.ly/SenseglutenOnline" target="_blank"><img src="assets/image/shoppingCart.png" alt=""></a>
            <!--<a href="#" class="centered" id="translateCa" data-text="ca">CAT</a>-->
        </div>
        <div class="enlaces-header" id="enlaces">
            <a data-lang="inicio" href="#inicio">Inicio</a>
            <a data-lang="section-description" href="#section-description">Quienes somos</a>
            <a data-lang="section-bakery" href="#section-bakery">Panadería</a>
            <a data-lang="section-pastrie" href="#section-pastrie">Bollería</a>
            <a data-lang="section-cake" href="#section-cake">Pastelería</a>
            <a data-lang="section-pricing" href="#section-pricing">Algunos de nuestros precios</a>
            <a data-lang="section-reviews" href="#section-reviews">Clientes satisfechos</a>
        </div>
    </nav>
    <div class="contenido-header">
        <div class="textos-header">
            <h1 data-lang="titulo-logo">Obrador sin Gluten y sin Lactosa</h1>
            <a data-lang="donde-encontrarnos" href="https://www.google.com/maps/place/Pastisseria+Sense+gluten/@41.4262338,2.184131,16z/data=!4m13!1m7!3m6!1s0x12a4bcd109635927:0xcaeafb73422c10f1!2sCarrer+de+Berenguer+de+Palou,+147,+08027+Barcelona!3b1!8m2!3d41.427167!4d2.1915125!3m4!1s0x12a4bdacd84939c5:0x5ab3773f639ae853!8m2!3d41.427167!4d2.1915125"  target="_blank"> Donde encontrarnos</a>
        </div>
        <div class="img-header">
            <img src="assets/image/logoEmpty.png" alt="">
        </div>
    </div>
    <div class="wave-bottom">
        <svg viewBox="0 0 500 150" preserveAspectRatio="none"
            style="height: 100%; width: 100%;">
            <path d="M-3.68,28.90 C253.67,102.92 368.22,-62.87 500.84,36.80 L505.92,153.25 L0.00,150.00 Z"
                style="stroke: none;"></path>
        </svg>
    </div>
</header>
