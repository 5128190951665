<section id="section-cake" class="contenedor">
    <h2 data-lang="section-cake-h2" class="display-4 text-center">Pasteles</h2>
    <p data-lang="section-cake-p" class="text-center"> Deliciosos, esponjosos, sabrosos pasteles realizados desde inicio a fin con un riquísimo sabor.</p>
<div class="row">
    <div class="column">
      <img src="assets/image/cake/Pastel-Chocolate-Blanco-Sin-Gluten.jpg">
      <img src="assets/image/cake/Pastel-chocolate-sin-gluten-sin-lactosa.jpg">
      <img src="assets/image/cake/Pastel-trufa-crujiente-almendras-sin-gluten-sin-lactosa_mitad.jpg">
      <img src="assets/image/cake/Pastel_individual_mouse_limon_verano_retailed.jpg">
    </div>
    <div class="column">
        <img src="assets/image/cake/Pastel_fresa_trufa_sin_lactosa_sin_gluten.jpg">
        <img src="assets/image/cake/unicornio-sin-gluten-sin-lactosa.jpg">
        <img src="assets/image/cake/Pastel_mona_crema_quemada_y_almendra_sin_lactosa_sin_gluten.jpg">
        <img src="assets/image/cake/Pastel_nata_sin_lactosa_sin_gluten.jpg">
        <img src="assets/image/cake/Pastel_mona_nata_sin_lactosa_sin_gluten.jpg">
    </div>
    <div class="column">
        <img src="assets/image/cake/Pastel_trufa_chocolate_negro_sin_lactosa_sin_gluten_mitad.jpg">
        <img src="assets/image/cake/crema_cremada_nata_almendra_sin_lactosa_sin_gluten.jpg">
        <img src="assets/image/cake/Hojaldre-Frutas-Sin-Gluten-Sin-Lactosa.jpg">
        <img src="assets/image/cake/rosca_nata_sin_lactosa.jpg">
        <img src="assets/image/cake/Pastel_mona_chocolate_sin_lactosa_sin_gluten.jpg">
        <img src="assets/image/cake/brazo_gitano_nata.jpg">
    </div>
    <div class="column">
        <img src="assets/image/cake/Brazo_gitano_nata_con_almendra_sin_lactosa_sin_gluten.jpg">
        <img src="assets/image/cake/chocolate_noche_dia_sin_gluten_sin_lactosa.jpg">
        <img src="assets/image/cake/Pastel_individual_mouse_frambuesa_acida_crocant_chocolate_70_retailed.jpg">
        <img src="assets/image/cake/nata_chocolate_con_crema_cremada_sin_gluten_sin_lactosa.jpg">
        <img src="assets/image/cake/pastel-chocolate-almendra-Sin-gluten-sin-lactosa_mitad.jpg">
    </div>
  </div>
</section>
