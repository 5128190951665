import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bakery',
  templateUrl: './bakery.component.html',
  styleUrls: ['./bakery.component.css']
})
export class BakeryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
