<section id="section-pricing" class="contenedor">
    <h2 data-lang="section-pricing-h2" class="display-4 text-center">Algunos precios de nuestros riquísimos productos</h2>
    <div class="row">
      <div class="col-12 col-md-4 mb-4 card-acerca">
        <div class="card card-outline-success">
          <div class="card-header bg-success text-white text-center" >
            <h2 data-lang="pricing-1-title-1">Cruasan de Chocolate</h2>
            <h4>16,95 € / Kg</h4>
          </div>
          <div class="card-body">
            <p data-lang="pricing-1-description-1" class="card-text">Delicioso y esponjoso cruasan de chocolate sin gluten ni lactosa</p>
          </div>
          <ul class="list-group">
            <li data-lang="pricing-1-description-2" class="list-group-item"><i class="fa fa-fw fa-thermometer-full" aria-hidden="true"></i> Realizado en nuestro obrador cada día</li>
            <li data-lang="pricing-1-description-3" class="list-group-item"><i class="fa fa-fw fa-star" aria-hidden="true"></i> Este es solo uno de ellos, ven a visitarnos y verás que diversidad.</li>
          </ul>
          <div class="card-footer text-center">
            <a id="oneA" target="_blank" href="whatsapp://send/?phone=+34617986372&amp;text=Quisiera%20recibir%20mas%20informacion%20sobre%20el%20croassant" class="btn btn-success btn-lg">Lo quiero!</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-4  card-acerca">
        <div class="card card-outline-warning">
          <div class="card-header bg-warning text-white text-center">
            <h2 data-lang="pricing-2-title-1">Pasteles sin gluten y sin lactosa</h2>
            <h4 data-lang="pricing-2-title-2">Desde 27.5€ peso de 1Kg para 10 personas (30€ Sacher)</h4>
          </div>
          <div class="card-body">
            <p data-lang="pricing-2-description-1" class="card-text">Los pasteles son creados a gusto del consumidor. Dinos que es lo que te gusta, la intolerancia que tienes y así crearemos ese rico pastel tan deseado.</p>
          </div>
          <ul class="list-group">
            <li data-lang="pricing-2-description-2" class="list-group-item"><i class="fa fa-fw fa-thumbs-up" aria-hidden="true"></i> Sin gluten, si quieres, también sin lactosa</li>
            <li data-lang="pricing-2-description-3" class="list-group-item"><i class="fa fa-fw fa-hand-o-right" aria-hidden="true"></i >Explíquenos alguna otra intolerancia y encantados le realizaremos el pastel. </li>
            <li data-lang="pricing-2-description-4" class="list-group-item"><i class="fa fa-fw fa-star" aria-hidden="true"></i> Con pasión por la reposteria</li>
          </ul>
          <div class="card-footer text-center">
            <a id="twoA" target="_blank" href="whatsapp://send/?phone=+34617986372&amp;text=Me%20gustaria%20recibir%20mas%20informacion%20sobre%20los%20pasteles" class="btn btn-warning btn-lg">Me lo pido</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-4  card-acerca">
        <div class="card card-outline-danger">
          <div class="card-header bg-danger text-white text-center"> 
            <h2 data-lang="pricing-3-title-1">Xapata sin gluten</h2>
            <h4>1.5 €</h4>
          </div>
          <div class="card-body">
            <p data-lang="pricing-3-description-1" class="card-text">Con harina de teff y trigo sarraceno sin gluten y reposada durante 24h para obtener el máximo sabor.</p>
          </div>
          <ul class="list-group">
            <li data-lang="pricing-3-description-2" class="list-group-item"><i class="fa fa-fw fa-arrow-circle-right" aria-hidden="true"></i> Harina de teff y trigo sarraceno <br> Una pizca de sal</li>
            <li data-lang="pricing-3-description-3" class="list-group-item"><i class="fa fa-fw fa-thermometer-full" aria-hidden="true"></i> Levadura con un poco de horno</li>
            <li data-lang="pricing-3-description-4" class="list-group-item"><i class="fa fa-fw fa-heart" aria-hidden="true"></i> Amor por los productos que elaboramos</li>
          </ul>
          <div class="card-footer text-center">
            <a target="_blank" id="threeA" href="whatsapp://send/?phone=+34617986372&amp;text=Quisiera%20recibir%20mas%20informacion%20sobre%20esa%20maravillosa%20Xpata" class="btn btn-danger btn-lg">¡Quiero uno!</a>
          </div>
        </div>
      </div>
    </div>
  </section>
