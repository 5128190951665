<app-header></app-header>
<main>
    <app-description></app-description>
    <app-servicio></app-servicio>
    <app-bakery></app-bakery>
    <app-carousel></app-carousel>
    <app-cake></app-cake>
    <app-price></app-price>
    <app-review></app-review>
</main>
<app-footer></app-footer>