<section class="galeria contenedor" id="section-bakery">
    <h2 data-lang="section-bakery" class="display-4 text-center">Panadería</h2>
    <p data-lang="section-bakery-description" class="text-center">Panes diversos y sabrosos con fermentación de larga duración. <br>
        (Si clicas en las imágenes obtendrás un poco más de información)</p>
    <article class="galerias">
        <div class="galeria-img">
            <img src="assets/image/bread/Pan_variado_sin_gluten.jpg" alt="">

            <div class="extra-galeria">
                <p data-lang="section-bakery-contact">Encontrarás variados tipos de pan, si quieres más información ponte en contacto con <a id="oneA" target="_blank" href="whatsapp://send/?phone=+34617986372&amp;text=Quisiera%20recibir%20mas%20informacion%20sobre%20el%20buenisimo%20pan%20que%20teneis..."> nosotros</a> </p>
            </div>
        </div>
        <div class="galeria-img">
            <img src="assets/image/bread/Pa-Rustic-Sense-Gluten.jpg" alt="">

            <div class="extra-galeria">
                <p data-lang="section-bakery-gallery-1">Para la elaboración del pan rústico se utiliza una selección de varios tipos de harina</p>
            </div>
        </div>
        <div class="galeria-img">
            <img src="assets/image/bread/Pan_rosa_remolacha_sin_lactosa_sin_gluten.jpg" alt="">

            <div class="extra-galeria">
                <p data-lang="section-bakery-gallery-2">La deliciosa y saludable remolacha le da ese color característico rosa tan apetecible.</p>
            </div>
        </div>
        <div class="galeria-img">
            <img src="assets/image/bread/Pan_hamburguesa_sin_gluten_sin_lactosa_psensegluten.jpg" alt="">

            <div class="extra-galeria">
                <p data-lang="section-bakery-gallery-3">Pan de hamburguesa extremadamente esponjoso, tierno y sobretodo delicioso </p>
            </div>
        </div>
        <div class="galeria-img">
            <img src="assets/image/bread/Pan_semillas_sin_gluten_psensegluten.jpg" alt="">

            <div class="extra-galeria">
                <p data-lang="section-bakery-gallery-4">Pan de semillas llamado por nuestros clientes pan suculento, adivinad el motivo...</p>
            </div>
        </div>
        <div class="galeria-img">
            <img src="assets/image/bread/Pan-Bagette-sin-lactosa-sin-gluten.jpg" alt="">

            <div class="extra-galeria">
                <p data-lang="section-bakery-gallery-5">Bagette larga con harina selecta para obtener el mejor sabor</p>
            </div>
        </div>
    </article>
</section>
