<section class="acerca-de contenedor" id="section-description">
    <h3 data-lang="quienes-somos-h3" class="display-4 text-center">¿Quienes somos?</h3>
    <p data-lang="quienes-somos-titulo">Pastelería Sin Gluten ha nacido desde el corazón.</p>
    <p data-lang="qs-descripcion-1">Empezó como una ilusión, que fué tomando forma hasta que a principios del 2019 pudimos abrir nuestro obrador en Barcelona.
    Llevamos poco en el barrio de Sant Andreu pero nuestra entrega es total, nos esmeramos por otorgar la máxima calidad a nuestros productos,
    que son elaborados de forma artesanal.</p>
    <h6 data-lang="qs-descripcion-1.5"> Desde el Lunes 5 de julio tenemos el orgullo de presentar y poner a vuestra disposición nuestra <a href="https://bit.ly/SenseglutenOnline" target="_blank">tienda online </a>que reparte por la ciudad de Barcelona</h6>
    <p data-lang="qs-descripcion-2"> Todos los que lo deseen pueden adquirir nuestros productos solicitándolos por <a href="#telf"> teléfono </a>, <a href="https://bit.ly/SenseglutenOnline" target="_blank">tienda online </a>,  <a  href="https://www.facebook.com/PastisseriaSenseGluten/" target="_blank">facebook</a> / <a href="https://www.instagram.com/psensegluten/"  target="_blank">instagram</a> o en nuestra <a href="https://www.google.com/maps/place/Pastisseria+Sense+gluten/@41.4262338,2.184131,16z/data=!4m13!1m7!3m6!1s0x12a4bcd109635927:0xcaeafb73422c10f1!2sCarrer+de+Berenguer+de+Palou,+147,+08027+Barcelona!3b1!8m2!3d41.427167!4d2.1915125!3m4!1s0x12a4bdacd84939c5:0x5ab3773f639ae853!8m2!3d41.427167!4d2.1915125"  target="_blank">tienda física.</a></p>
    <p data-lang="qs-descripcion-3"> Nos sentimos muy orgullosos de comunicaros que nos hemos acreditado como empresa gluten free a través de la <b>Asociación de Celíacos de Cataluña  </b></p>
    <p > <img src="assets/image/AssociacioCeliacs_400.png" class="center" alt="Acreditación Asociación Celíacos" id="asociationCeliacs"></p>
</section>
