<section class="servicio-cont" id="servicio-cont">
    <div class="servicio ">
        <div class="img-servicio">
            <img src="assets/image/cake/unicornio-sin-gluten-sin-lactosa.jpg" alt="" id="ilustracionSVG">
        </div>
        <article class="contenido-servicio">
            <h3 data-lang="contenido-servicio-products">Nuestros productos</h3>
            <p data-lang="contenido-servicio-description">Los ingredientes ofrecidos en todos nuestros productos son de la más alta calidad, fabricados siguiendo un proceso profesional y con mucho cariño.
                En <strong>PSenseGluten </strong>solo ofrecemos productos de la más&nbsp;<span style="text-decoration: underline;">alta calidad</span>.&nbsp;Estos productos <strong>&nbsp;</strong><strong>no pueden encontrase en los supermercados</strong>, pues se realizan el mismo día, bajo un minucioso proceso artesanal consiguiendo un producto único y delicioso.
            </p>
            <p data-lang="contenido-servicio-find">En nuestras instalaciones puede encontrar los siguientes productos:</p>
            <ul>
                <li><a data-lang="section-bakery" href="#section-bakery">Panadería</a></li>
                <li><a data-lang="section-pastrie" href="#section-pastrie">Bollería</a></li>
                <li><a data-lang="section-cake" href="#section-cake">Pastelería</a></li>
            </ul>
        </article>
    </div>
</section>
