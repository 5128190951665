<section id="section-reviews"  class="contenedor">
    <h2 data-lang="section-reviews-h2" class="display-4 text-center">Comentarios de nuestros clientes</h2>
    <div class="row">
        <div class="col-12 col-md-6 col-xl-3">
        <blockquote class="blockquote p-3">
            <p data-lang="section-reviews-p-1" ><i class="fa fa-fw fa-quote-left text-secondary" aria-hidden="true"></i>Este es el mejor sitio sin gluten que he probado. ¡Recomiendo altamente el donut!<i class="fa fa-fw fa-quote-right text-secondary" aria-hidden="true"></i></p>
            <footer data-lang="section-reviews-footer-1" class="blockquote-footer resenya">Marc Griell <time datetime="2017-06-15T14:35">(11 Febrero 2020)</time></footer>
        </blockquote>
        </div>
        <div class="col-12 col-md-6 col-xl-3">
        <blockquote class="blockquote p-3">
            <p data-lang="section-reviews-p-2"><i class="fa fa-fw fa-quote-left text-secondary" aria-hidden="true"></i> Nunca antes he podido comer pasteles sin dejarme el bolsillo, este tiene con buenos precios y además delicioso<i class="fa fa-fw fa-quote-right text-secondary" aria-hidden="true"></i></p>
            <footer data-lang="section-reviews-footer-2" class="blockquote-footer resenya">Antonio Trabillo <time datetime="2017-07-09T19:12">(14 Diciembre 2019)</time></footer>
        </blockquote>
        </div>
        <div class="col-12 col-md-6 col-xl-3">
        <blockquote class="blockquote p-3">
            <p data-lang="section-reviews-p-3" ><i class="fa fa-fw fa-quote-left text-secondary" aria-hidden="true"></i>He probado diferentes productos y me los comía todos.<i class="fa fa-fw fa-quote-right text-secondary" aria-hidden="true"></i></p>
            <footer data-lang="section-reviews-footer-3" class="blockquote-footer resenya">Anabel Francis <time datetime="2017-09-01T11:19">(11 Abril 2019)</time></footer>
        </blockquote>
        </div>
        <div class="col-12 col-md-6 col-xl-3">
        <blockquote class="blockquote p-3">
            <p data-lang="section-reviews-p-4"><i class="fa fa-fw fa-quote-left text-secondary" aria-hidden="true"></i>El pastel Sacher está de muerte se ha convertido en mi nuevo pastel favorito .... y sin gluten ni lactosa ... flipo!<i class="fa fa-fw fa-quote-right text-secondary" aria-hidden="true"></i></p>
            <footer data-lang="section-reviews-footer-4" class="blockquote-footer resenya">Jose Barrascora <time datetime="2017-08-12T08:43">(5 Junio 2020)</time></footer>
        </blockquote>
        </div>
    </div>
</section>
