<section id="section-pastrie" class="contenedor">
    <h2 data-lang="section-pastrie" class="display-4 text-center">Bollería</h2>
    <p data-lang="section-pastrie-p1" class="text-center">No solo hay ingredientes también tienen pasión, cariño e ilusión.</p>
    <p data-lang="section-pastrie-p2" class="text-center">Versión sin gluten y sin lactosa de las recetas tradicionales de la bollería que tanto gusta</p>
    <div id="carousel" class="carousel slide" data-ride="carousel" data-interval="2600">
        <ol class="carousel-indicators">
        <li data-target="#carousel" data-slide-to="1" class="active"></li>
        <li data-target="#carousel" data-slide-to="2"></li>
        <li data-target="#carousel" data-slide-to="3"></li>
        <li data-target="#carousel" data-slide-to="4"></li>
        <li data-target="#carousel" data-slide-to="5"></li>
        <li data-target="#carousel" data-slide-to="6"></li>
        <li data-target="#carousel" data-slide-to="7"></li>
        <li data-target="#carousel" data-slide-to="8"></li>
        <li data-target="#carousel" data-slide-to="9"></li>
        <li data-target="#carousel" data-slide-to="10"></li>
        </ol>
        <div class="carousel-inner" role="listbox">
        <div class="carousel-item active">
            <img src="assets/image\bolleria\carrusel\Croassant_salado_salchicha_sin_lactosa_sin_gluten.jpg" alt="Slide #1" class="d-block img-fluid">
            <div class="carousel-caption">
            <h3 data-lang="carousel-title-1" class="display-4">Cruasan salado</h3>
            <h6 data-lang="carousel-description-1">Cruasan de salchicha y queso sin lactosa y gluten</h6>
            </div>
        </div>
        <div class="carousel-item">
            <img src="assets/image/bolleria/carrusel/Pizza_atun_pimiento_bacon_sin_lactosa_sin_gluten.jpg" alt="Slide #2" class="d-block img-fluid">
            <div class="carousel-caption">
            <h3 data-lang="carousel-title-2" class="display-4">Pizza atún y más</h3>
            <h6 data-lang="carousel-description-2" >Riquísima pizza de atún, pimiento, bacon con mucho sabor y nada de gluten y lactosa</h6>
            </div>
        </div>
        <div class="carousel-item">
            <img src="assets/image/bolleria/carrusel/Palmeras_sin_lactosa_sin_gluten_psensegluten.com.JPG" alt="Slide #3" class="d-block img-fluid">
            <div class="carousel-caption">
            <h3 data-lang="carousel-title-3" class="display-4">Palmeras</h3>
            <h6 data-lang="carousel-description-3">Todo un clásico, bueno, sabroso y sin gluten ni lactosa</h6>
            </div>
        </div>
        <div class="carousel-item">
            <img src="assets/image/bolleria/carrusel/Briox-Sin-Gluten-Sin-Lactosa.jpg" alt="Slide #4" class="d-block img-fluid">
            <div class="carousel-caption">
            <h3 data-lang="carousel-title-4" class="display-4">Brioche diversos</h3>
            <h6 data-lang="carousel-description-4">Brioche dulce y de muchos tipos esponjosos con mucho sabor !!!</h6>
            </div>
        </div>
        <div class="carousel-item">
            <img src="assets/image/bolleria/carrusel/Croassant_psensegluten.com.JPG" alt="Slide #5" class="d-block img-fluid">
            <div class="carousel-caption">
            <h3 data-lang="carousel-title-5" class="display-4">Cruasan francés</h3>
            <h6 data-lang="carousel-description-5">Esponjoso, sabroso, delicioso, podriamos seguir un buen rato ... pero mejor probarlo.</h6>
            </div>
        </div>
        <div class="carousel-item">
            <img src="assets/image/bolleria/carrusel/Coca_crema_y_fruta_sin_lactosa_sin_gluten.jpg" alt="Slide #6" class="d-block img-fluid">
            <div class="carousel-caption">
            <h3 data-lang="carousel-title-6" class="display-4">Hojaldre de frutas</h3>
            <h6 data-lang="carousel-description-6">Crujiente y laminoso hojaldre bajo una crema artesana que contiene deliciosa y fresca fruta.</h6>
            </div>
        </div>
        <div class="carousel-item">
            <img src="assets/image/bolleria/carrusel/Croassant_chocolate_sin_lactosa_sin_gluten.jpg" alt="Slide #7" class="d-block img-fluid">
            <div class="carousel-caption">
            <h3 data-lang="carousel-title-7" class="display-4">Cruasan de chocolate</h3>
            <h6 data-lang="carousel-description-7">Cruasan de chocolate sin gluten y sin lactosa</h6>
            </div>
        </div>
        <div class="carousel-item">
            <img src="assets/image/bolleria/carrusel/Alfajores_sin_lactosa_sin_lactosa_sin_gluten.jpg" alt="Slide #8" class="d-block img-fluid">
            <div class="carousel-caption">
            <h3 data-lang="carousel-title-8" class="display-4">Alfajores argentinos</h3>
            <h6 data-lang="carousel-description-8">Exquisito dulce de leche envuelto en unas obleas tremendamente sabrosas</h6>
            </div>
        </div>
        <div class="carousel-item">
            <img src="assets/image/bolleria/carrusel/Rollos_sobrasada_psensegluten.com.jpg" alt="Slide #9" class="d-block img-fluid">
            <div class="carousel-caption">
            <h3 data-lang="carousel-title-9" class="display-4">Rolls de sobrasada</h3>
            <h6 data-lang="carousel-description-9">Masa de hojaldre con suave sobrasada adornada con sésamo ... delicioso !!!</h6>
            </div>
        </div>
        <div class="carousel-item">
            <img src="assets/image/bolleria/carrusel/RockAndRoll_psensegluten.com.jpg" alt="Slide #10" class="d-block img-fluid">
            <div class="carousel-caption">
            <h3 data-lang="carousel-title-10" class="display-4">Rolls de ....</h3>
            <h6 data-lang="carousel-description-10">Bautizados como Choc & Roll están creados con ...... ¡Ven a descubrirlo!</h6>
            </div>
        </div>
        </div>
        <a href="#carousel" class="carousel-control-prev" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
        </a>
        <a href="#carousel" class="carousel-control-next" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
        </a>
    </div>
</section>
