<footer class="bg-light">
    <div class="container py-3 py-sm-5">
        <div class="row">
        <div class="col-12 col-sm-6 col-lg-4">
            <h6 data-lang="section-footer-information">Información</h6>
            <ul class="list-unstyled">
            <li><a data-lang="inicio" href="index.html#inicio">Inicio</a></li>
            <li><a data-lang="section-description" href="index.html#section-description">Quienes somos</a></li>
            <li><a data-lang="section-bakery" href="index.html#section-pastrie">Bollería</a></li>
            <li><a data-lang="section-cake" href="index.html#section-cake">Pastelería</a></li>
            <li><a data-lang="footer-pricing" href="index.html#section-pricing">Precios</a></li>
            <li><a data-lang="footer-comments" href="index.html#section-reviews">Comentarios Clientes</a></li>
            </ul>
        </div>
        <div class="col-12 col-sm-6 col-lg-4">
            <h6>Follow us</h6>
            <ul class="list-unstyled">
            <li><a href="https://www.facebook.com/PastisseriaSenseGluten/" target="_blank"target="_blank"><i class="fa fa-fw fa-facebook" aria-hidden="true"></i> Facebook</a></li>
            <li><a href="https://www.instagram.com/psensegluten/"><i class="fa fa-fw fa-instagram" aria-hidden="true" target="_blank"></i> Instagram</a></li>
            <li><a href="https://www.google.com/maps/place/Pastisseria+Sense+gluten/@41.4262338,2.184131,16z/data=!4m13!1m7!3m6!1s0x12a4bcd109635927:0xcaeafb73422c10f1!2sCarrer+de+Berenguer+de+Palou,+147,+08027+Barcelona!3b1!8m2!3d41.427167!4d2.1915125!3m4!1s0x12a4bdacd84939c5:0x5ab3773f639ae853!8m2!3d41.427167!4d2.1915125"><i class="fa fa-fw fa-map-marker" aria-hidden="true" target="_blank"></i> Google</a></li>
            </ul>
        </div>
        <div class="col-12 col-sm-6 col-lg-4">
            <h6 data-lang="footer-our-location">Nuestra localización:</h6>
            <address>
            <h6>Psensegluten</h6>
            <a href="https://www.google.com/maps/place/Pastisseria+Sense+gluten/@41.4262338,2.184131,16z/data=!4m13!1m7!3m6!1s0x12a4bcd109635927:0xcaeafb73422c10f1!2sCarrer+de+Berenguer+de+Palou,+147,+08027+Barcelona!3b1!8m2!3d41.427167!4d2.1915125!3m4!1s0x12a4bdacd84939c5:0x5ab3773f639ae853!8m2!3d41.427167!4d2.1915125"  target="_blank">Berenguer de palou, 147</a><br>
            <a href="https://www.google.com/maps/place/Pastisseria+Sense+gluten/@41.4262338,2.184131,16z/data=!4m13!1m7!3m6!1s0x12a4bcd109635927:0xcaeafb73422c10f1!2sCarrer+de+Berenguer+de+Palou,+147,+08027+Barcelona!3b1!8m2!3d41.427167!4d2.1915125!3m4!1s0x12a4bdacd84939c5:0x5ab3773f639ae853!8m2!3d41.427167!4d2.1915125"  target="_blank">08027 Barcelona, Spain</a>  <br>
            <i class="fa fa-fw fa-phone" aria-hidden="true"></i><span class="sr-only"><a name="telf">  Teléfono:</a></span> <img style="width:145px;height:25px;" src="assets/image/telfContactoTW.png" /><br>
            </address>
        </div>
        </div>
        <div class="row">
        <div class="col-12 col-sm-6 col-lg-9">
            <ul class="list-inline">
                <li class="list-inline-item">&copy; 2019 www.PSensegluten.com.</li>
                <li data-lang="footer-text" class="list-inline-item">Estamos para endulzarte la vida.</li>
            </ul>
        </div>
        </div>
    </div>
</footer>